import React from "react";
import "./ServiceContactUs.css";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

function ContactUs() {
  const { t } = useTranslation();

  return (
    <>
      <div className="tkdn-container-definition">
        <div className="tkdn-section-header">
          <div className="border-title-vpti" />
          <h1 className="tkdn-section-title">{t("contact_title")}</h1>
        </div>
        <div>
          <Row>
            <Col md={2}>
              <img
                className="barcode-img"
                src={require(`../../../images/LVV/barcode.png`)}
                alt={'Contact Us'}
              />
            </Col>
            <Col md={9}>
              <Row>
                <p className="contact-detail">
                  {t("lvv_contact_us")}
                </p>
              </Row>
              <Row>
                <Col md={3}>
                  <div>
                    <FontAwesomeIcon icon={faWhatsapp} style={{ color: 'black', marginRight: '15px', }} />
                    <a href="https://wa.me/+6285121044449" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 400, color: 'black', fontSize: '15px' }}>
                      +62 851-2104-4449
                    </a>
                  </div>
                  <div style={{ margin: '10px 0 0 0'}}>
                    <FontAwesomeIcon icon={faPhone} style={{ color: 'black', marginRight: '15px' }}/>
                    <a href="tel:02122606207" style={{ fontWeight: 400, color: 'black', fontSize: '15px' }}>
                      +62 21-2260-6207
                    </a>
                  </div>
                </Col>
                <Col>

                  <div>
                    <FontAwesomeIcon icon={faEnvelope} style={{ color: 'black', marginRight: '15px' }}/>
                    <a href="mailto:lvvgrk.nek@anindya.biz" style={{ fontWeight: 400, color: 'black', fontSize: '15px' }}>
                      lvvgrk.nek@anindya.biz
                    </a>
                  </div>
                  <div style={{ margin: '10px 0 0 0'}}>
                    <FontAwesomeIcon icon={faLocationDot} style={{ color: 'black', marginRight: '15px', marginLeft: '2px' }}/>
                    <a href="https://www.google.com/maps/search/?api=1&query=Springhill Office Tower, Lt.19, Pademangan Timur, Jakarta Utara" target="_blank" rel="noopener noreferrer"  style={{ fontWeight: 400, color: 'black', fontSize: '15px' }}>
                      Springhill Office Tower, Lt.19, Pademangan Timur, Jakarta Utara
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default ContactUs;
